import "./App.css";
import {
  Layout,
  Row,
  Col,
  Card,
  List,
  Button,
  Space,
  Carousel,
  Image,
  Avatar,
} from "antd";
import {
  EnvironmentOutlined,
  WhatsAppOutlined,
  InstagramOutlined,
  TwitterOutlined,
  FacebookOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";
import React from "react";
import footerImage from "./assets/images/Origin Research Logo_circle_Alpha_New_Square_Square.png";
import profilePict from "./assets/images/testPict.png";
const { Header, Content, Footer } = Layout;
const { Meta } = Card;

const dataProfile = [
  {
    carouselPict1: "/FotoOpik/carouselPict1_opik.png",
    carouselPict2: "/FotoOpik/carouselPict2_opik.png",
    carouselPict3: "/FotoOpik/carouselPict3_opik.png",
    carouselPict4: "/FotoOpik/carouselPict4_opik.png",
    profilePict: "/FotoOpik/profPict_opik.jpeg",
    name: "Muhammad Taufiqurrahman",
    desc: "Co-Founder & Creative Technologist of Origin Research",
    location: "Bandung, Indonesia",
  },
];

const dataContact = [
  {
    title: "Contact via WhatsApp",
    icon: <WhatsAppOutlined />,
    link: "http://wa.me/+6289661527021",
  },
  {
    title: "Follow on Instagram",
    icon: <InstagramOutlined />,
    link: "http://instagram.com/opikqurrahman",
  },
  {
    title: "Follow on Twitter",
    icon: <TwitterOutlined />,
    link: "https://twitter.com/0_Pict",
  },
  {
    title: "Follow on Linkedin",
    icon: <LinkedinOutlined />,
    link: "https://id.linkedin.com/in/muhammad-taufiqurrahman-217486157",
  },
  {
    title: "ArtStation Portfolio",
    icon: "",
    link: "https://www.artstation.com/aegis255",
  },
];

function App() {
  return (
    <Layout className="layout">
      <Content
        style={{
          padding: "20px",
          background: "#fff",
        }}
      >
        <Row justify="center">
          <Card
            style={{
              width: "350px",
            }}
            bordered
            cover={
              <Carousel autoplay style={{ width: "100%" }}>
                <div>
                  <Image
                    alt="example"
                    src={dataProfile[0].carouselPict1}
                    style={{
                      maxWidth: "100%",
                      height: 350,
                    }}
                  />
                </div>
                <div>
                  <Image
                    alt="example"
                    src={dataProfile[0].carouselPict2}
                    style={{ maxWidth: "100%", height: 350 }}
                  />
                </div>
                <div>
                  <Image
                    alt="example"
                    src={dataProfile[0].carouselPict3}
                    style={{ width: "100%", height: 350 }}
                  />
                </div>
                <div>
                  <Image
                    alt="example"
                    src={dataProfile[0].carouselPict4}
                    style={{
                      width: "100%",
                      height: 350,
                    }}
                  />
                </div>
              </Carousel>
            }
          >
            <Space
              direction="vertical"
              size="large"
              style={{
                display: "flex",
              }}
            >
              <Meta
                avatar={<Avatar src={dataProfile[0].profilePict} size={64} />}
                title={dataProfile[0].name}
                description={
                  <>
                    <p>{dataProfile[0].desc}</p>
                    <Row>
                      <EnvironmentOutlined /> &nbsp; {dataProfile[0].location}
                    </Row>
                  </>
                }
              />
              <List
                size="small"
                dataSource={dataContact}
                renderItem={(item) => (
                  <List.Item>
                    <Button
                      type="primary"
                      shape="round"
                      size="large"
                      style={{
                        width: "100%",
                        background: "#58c3b5",
                        borderColor: "#58c3b5",
                      }}
                    >
                      <a href={item.link}>
                        {item.icon} {item.title}
                      </a>
                    </Button>
                  </List.Item>
                )}
              />
            </Space>
          </Card>
        </Row>
      </Content>
      <Footer
        style={{
          textAlign: "center",
        }}
      >
        <Row justify="center">
          <div className="logo">
            <img
              src={footerImage}
              alt="Logo"
              style={{ width: 60, height: 60 }}
            />
            <a href="http://google.com" style={{ color: "#000000" }}>
              <b>Origin.Research</b>
              <sup style={{ color: "#58c3b5" }}>
                <b>++</b>
              </sup>
            </a>
          </div>
        </Row>
        Copyright &copy; 2023 | PT. Origin Kreasi Teknologi
      </Footer>
    </Layout>
  );
}

export default App;
